.overlay {
    width: 100vw;
    height: 100vh;
    filter: blur(6px);
    transition: all 0.3s ease-in;
}

.overlay--transparent {
    filter: none;
}

.overlay__actions {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.overlay__actions__item {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--idle-fill,#fff);
    color: var(--idle-stroke,black);
    border: 1px solid var(--idle-stroke,black);
    filter: none;
    padding: 16px;
    width: 20%;
    height: 40px;
    min-width: 200px;
    margin: 16px;
    cursor: pointer;
    transition: all var(--transition,0.2s) ease-out;
}

.overlay__actions__item:hover {
    background-color: var(--hover-fill,#fff);
    color: var(--hover-stroke,black);
    border: 1px solid var(--hover-stroke,black);
}

.overlay__sidebar {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.day-cards {
    position: absolute;
    top: 0px;
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 2;
    
    perspective: 800px;
    perspective-origin: center;
}

.day-cards__card {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border: 2px solid black;
    background-color: rgba(255,255,255,0.5);
    transform-style: preserve-3d;
    transition: transform 1s;
    cursor: pointer;
    border: 2px solid black;
    font-family: Roboto;
    font-weight: bold;
    user-select: none;
}

.day-cards__card--selected,
.day-cards__card--next,
.day-cards__card--prev {
    min-width: 90px;
    min-height: 90px;
    display: flex;
}

.day-cards__card--prev {
    transform: rotateY(-45deg) translateZ(-40px) translateX(-50px);
}
.day-cards__card--next {
    transform: rotateY(45deg) translateZ(-40px) translateX(50px);
}
.day-cards__card--placeholder {
    background-color: transparent;
    border-color: transparent;
    cursor: initial;
}

.day-cards__card__rain, 
.day-cards__card__day {
    margin-bottom: 16px;
}

.day-cards__card__rain {
    position: relative;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateZ(45deg);
    width: 32px;
    height: 32px;
    border-radius: 5% 100% 100% 100%;
    overflow: hidden;
}

.day-cards__card__rain__text {
    transform: rotateZ(-45deg);
    z-index: 3;
}

.day-cards__card__rain__water {
    position: absolute;
    background-color: darkcyan;
    transform: rotateZ(45deg) translateX(-10px);
    width: 42px;
    height: 42px;
    top: 0px;
    left: 0px;
}

.home {
    position: absolute;
    bottom: 36px;
    width: 100%;
    z-index: 2;
    display: flex;
}

.home__button {
    background-color: rgba(255,255,255,0.5);
    border: 2px solid black;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}